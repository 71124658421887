import React from "react";
import { FaListUl } from "react-icons/fa";
import Checkbox from "./Checkbox";

interface Props {
  todo: string | number;
  setTodo: React.Dispatch<React.SetStateAction<string | number>>;
  submitForm: (e: React.FormEvent) => void;
}

const InputField: React.FC<Props> = ({ todo, setTodo, submitForm }) => {
  return (
    <>
      <form onSubmit={submitForm} style={{ marginTop: "45px" }}>
        <label style={{ marginBottom: "4px", fontSize: "14px" }}>
          {" "}
          Agregar Nota
        </label>
        <input
          type="text"
          value={todo}
          onChange={(e) => setTodo(e.target.value)}
          placeholder="Nota .."
        />
        <Checkbox />
        <button className="button-from-add" type="submit">
          <FaListUl style={{ marginRight: "5px" }} /> Agregar{" "}
        </button>
      </form>
      <h3 className="lista-de-tareas">Mis Notas</h3>
    </>
  );
};

export default InputField;
