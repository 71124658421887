import React, { useRef } from "react";
import { Todo } from "./Basecomp";
import { MdDeleteOutline } from "react-icons/md";
import { BsCheckCircle } from "react-icons/bs";
import { FiClock } from "react-icons/fi";
import chinche from "../img/chinche.png";

interface Props {
  todo: Todo;
  handleDelete: (id: number | string) => void;
  handleEdit: (id: number | string) => void;
}
const SingleTodo = ({ todo, handleDelete, handleEdit }: Props) => {
  const inputRef = useRef<HTMLLIElement>(null);

  return (
    <>
      <li
        ref={inputRef}
        style={{
          backgroundColor: todo.isDone ? "#90EE90" : "cornsilk",
          //  color: todo.isDone ? "gray" : "black",
          textDecoration: todo.isDone ? "line-through" : "none",
          position: "relative",
        }}
      >
        <div className="todo-container">
          <span
            className="fecha-single-todo"
            style={{
              fontSize: "12px",
              marginBottom: "12px",
              fontFamily: "roboto",
            }}
          >
            {todo.fecha} - <FiClock className="clock" /> {todo.totalHour}{" "}
          </span>
          <span className="todo-single-todo" style={{ fontSize: "14.5px" }}>
            {todo.todo}{" "}
          </span>
        </div>
        <span
          style={{ display: "flex", marginLeft: "14px", alignItems: "center" }}
        >
          <BsCheckCircle
            style={{
              cursor: "pointer",
              color: todo.isDone ? "gray" : "blue",
              marginRight: "12px",
            }}
            onClick={() => handleEdit(todo.id)}
          />
          <MdDeleteOutline
            style={{ cursor: "pointer", color: "red" }}
            onClick={() => handleDelete(todo.id)}
          />{" "}
        </span>{" "}
        <span className="chinche-single-todo">
          <img src={chinche} alt="" />
        </span>
      </li>
    </>
  );
};

export default SingleTodo;
