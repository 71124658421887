import React, { useState } from "react";
import alert from "../img/alert.png";

const Checkbox: React.FC = () => {
  const [isChecked, setIsChecked] = useState<boolean>(false);

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  console.log(isChecked, "isChecked");

  return (
    <div>
      <label className="label-check" style={label}>
        <input
          type="checkbox"
          checked={isChecked}
          onChange={handleCheckboxChange}
          //   style={{ margin: "0px"}}
          style={input}
        />
        Destacada
        <img src={alert} alt="" style={{ width: "19px", marginLeft: "4px" }} />
      </label>
    </div>
  );
};

const label = {
  display: "flex",
  marginBottom: "23px",
  alignItems: "center",
  fontSize: "14px",
};
const input = {
  margin: "0 8px 0 0",
};
export default Checkbox;
