import React, { useEffect, useState } from "react";
import InputField from "./InputField";
import SingleTodo from "./SingleTodo";
import { HiOutlineEmojiSad } from "react-icons/hi";
import { FcOpenedFolder } from "react-icons/fc";
import { v4 as uuidv4 } from "uuid";

export interface Todo {
  id: number | string;
  todo: string | number;
  isDone: boolean;
  fecha: string | number;
  totalHour?: string;
}
//////////////////////////////////////////////
const BaseComp: React.FC = () => {
  const list = localStorage.getItem("todoList");
  const [todo, setTodo] = useState<string | number>("");
  const [todoList, setTodoList] = useState<Todo[]>(
    list ? JSON.parse(list) : []
  );

  const date = new Date();
  const day: number = date.getDate();
  const month: string | number = date.getMonth() + 1; // Note: Months are zero-based, so we add 1 to get the correct month
  const year: number = date.getFullYear();

  ////////////////////  fecha

  const fecha = `${day}/${month}/${year}`;

  //////////  hora

  const hour: number = date.getHours();
  const minutes: number = date.getMinutes();
  const totalHour: string = `${hour}h:${minutes}m`;

  const submitForm = (e: React.FormEvent) => {
    e.preventDefault();
    if (todo)
      setTodoList([
        ...todoList,
        {
          id: uuidv4(),
          todo,
          isDone: false,
          fecha: fecha,
          totalHour: totalHour,
        },
      ]);
    setTodo("");
  };

  useEffect(() => {
    localStorage.setItem("todoList", JSON.stringify(todoList));
  }, [todoList]);

  //////

  const handleDelete = (id: number | string) => {
    const newList = todoList.filter((todo) => todo.id !== id);
    setTodoList(newList);
    localStorage.setItem("todoList", JSON.stringify(newList));
  };

  ////////////

  const handleEdit = (id: number | string) => {
    const newList = todoList.map((item) =>
      item.id === id ? { ...item, isDone: true } : item
    );

    setTodoList(newList);
  };

  return (
    <>
      <InputField todo={todo} setTodo={setTodo} submitForm={submitForm} />
      {
        <ul>
          {todoList.length > 0 ? (
            todoList.map((todo) => (
              <SingleTodo
                todo={todo}
                key={todo.id}
                handleDelete={handleDelete}
                handleEdit={handleEdit}
              />
            ))
          ) : (
            <h4
              style={{
                boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                padding: "18px 0",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                borderRadius: "9px",
                fontSize: "14px",
                fontWeight: "500",
              }}
            >
              Vacío{" "}
              {/* <HiOutlineEmojiSad
                style={{ marginLeft: "5px", fontSize: "20px" }}
              /> */}
              <FcOpenedFolder
                style={{
                  marginLeft: "5px",
                  fontSize: "26px",
                  marginBottom: "3px",
                }}
              />
            </h4>
          )}
        </ul>
      }
    </>
  );
};

export default BaseComp;
