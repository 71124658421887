import React, { useState } from "react";
import "./App.css";
import BaseComp from "./components/Basecomp";
import { AiOutlineCloudUpload } from "react-icons/ai";
import org from "./img/org.png";

interface Props {}

const App: React.FC = (props: Props) => {
  const picInStorage: string = localStorage.getItem("photo") || "";
  const [pic, setPic] = useState<boolean>(true);
  const [photo, setPhoto] = useState<string>(picInStorage);
  const defaultPic: string =
    "https://firebasestorage.googleapis.com/v0/b/organizador-4a2d0.appspot.com/o/354497ae-1325-48be-977d-4a34e190a6bc.jpg?alt=media&token=2af28a50-daec-4b8a-9657-4549b074d5e5&_gl=1*nqt03u*_ga*ODM5NjMwNDg0LjE2OTg0OTk1MjQ.*_ga_CW55HF8NVT*MTY5ODY5MTE4NS42LjEuMTY5ODY5MTMyMC42MC4wLjA.";

  //console.log(photo, "photo");

  // type Person = {
  //   name: string;
  //   age?: number; // con la question mark va a ser opcional
  // };
  ///  alternativa a alias type (type puede ser extendido en otro type)

  const handlePhotoSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    localStorage.setItem("photo", photo);
    setPic(true);
  };

  interface Person {
    name: string;
    age?: number;
  }
  let person: Person = {
    name: "Mis Notas:",
  };

  let arrOfPersons: Person[];

  let undifinedVariable: unknown;

  let nameOfApp2: string | number | boolean;
  let nameOfApp: string = "rganizador";

  nameOfApp2 = "nombre alternativo";
  return (
    <>
      <section
        style={{
          background: "white",
          width: "90",
          height: "100vh",
          padding: "25px 0 0 0",
          position: "relative",
        }}
      >
        {pic ? (
          <span className="pic-total" onClick={() => setPic(false)}>
            <img src={photo ? photo : defaultPic} alt="default-pic" />
          </span>
        ) : (
          <form action="" onSubmit={handlePhotoSubmit}>
            <input
              type="text"
              placeholder="Agregar URL foto"
              value={photo}
              onChange={(e) => setPhoto(e.target.value)}
            />
            <div className="buttons-containers">
              <button
                style={{
                  backgroundColor: "coral",
                  display: "flex",
                  alignItems: "center",
                }}
                type="submit"
              >
                <AiOutlineCloudUpload
                  style={{ marginRight: "4px", fontSize: "18.5px" }}
                />
                Foto
              </button>
              <button style={{ backgroundColor: "red" }}>Cancelar</button>
            </div>
          </form>
        )}

        <h1 style={Title}>
          <img src={org} alt="icono-org" style={imgOrg} />
          <span style={{ fontSize: "22px" }}>O</span> {nameOfApp}{" "}
        </h1>

        {/* ///  */}
        <BaseComp />
        {/* <section>
            <h2>Practica de useReducer</h2>
            <Counter />
          </section> */}

        <span className="fecha">{new Date().toLocaleDateString("es-AR")}</span>
      </section>
    </>
  );
};

interface TitleProps {
  display: string;
  justifyContent: string;
  alignItems: string;
  marginRight: string;
}
interface ImgProp {
  width: string;
  marginRight: string;
  marginBottom: string;
}

const Title: TitleProps = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  marginRight: "10%",
};

const imgOrg: ImgProp = {
  width: "34px",
  marginRight: "5px",
  marginBottom: "3px",
};

export default App;
